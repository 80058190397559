import CatalogApi from '@web/api/modules/Catalog'
import Token from '@web/common/token'
import { CatalogState, CatalogGettersTree, CatalogMutationsTree, CatalogActionsTree } from '@web/store/types/modules/catalog'

const state: CatalogState = {
  items: new Map(),
  perPage: 6,
  totalCount: 0
}

const getters: CatalogGettersTree = {
  items: ({ items }) => Array.from(items.values()),
  getPerPage: ({ perPage }) => perPage,
  getTotalCount: ({ totalCount }) => totalCount
}

const mutations: CatalogMutationsTree = {
  receiveItem (state, payload) {
    state.items.set(payload.id, payload)
  },
  removeItem (state, id) {
    state.items.delete(id)
  },
  setPerPage (state, payload) {
    state.perPage = payload
  },
  setTotalCount (state, payload) {
    state.totalCount = payload
  },
  logout (state) {
    state.items.clear()
    state.perPage = 6
    state.totalCount = 0
  }
}

const actions: CatalogActionsTree = {
  async fetchItems ({ getters, commit }, payload) {
    if (!Token.get()) {
      return Promise.resolve(false)
    }

    const page = payload?.page || 1
    const perPage = payload?.perPage || getters.getPerPage

    return CatalogApi.fetchAll({
      page,
      perPage
    })
      .then(({ data: items, headers }) => {
        commit('setTotalCount', parseInt(headers.get('x-pagination-total-count'), 10))
        items.forEach(item => commit('receiveItem', item))

        return Promise.resolve(true)
      })
  },

  async fetchCachedItems ({ getters, dispatch }, payload) {
    const page = payload?.page || 1
    const countItems = getters.items.length
    const perPage = payload?.perPage || getters.getPerPage

    if (countItems >= page * perPage) {
      return true
    }

    return dispatch('fetchItems', {
      page,
      perPage
    })
  },

  async addToSubscription ({ dispatch }, id) {
    if (!Token.get()) {
      return Promise.resolve(false)
    }

    return CatalogApi.addToSubscription(id)
      .then(() => {
        dispatch('subscription/fetchSubscriptions', undefined, { root: true })
        dispatch('stream/fetchUserCourseStreams', {}, { root: true })

        return Promise.resolve(true)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
// eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any
