/* istanbul ignore file */
import client, { ApiVersion } from '../client'
import CatalogMapper from '@web/mapper/CatalogMapper'
import HeaderMapper from '@web/mapper/HeaderMapper'

export default class Catalog {
  static fetchAll (payload: { perPage?: number, page?: number } = { perPage: 6, page: 1 }) {
    const params: {
      perPage?: number;
      page?: number;
    } = {
      perPage: payload.perPage || 6,
      page: payload.page || 1
    }
    return client.get('/catalog-position', {
      params
    })
      .then((response) => {
        return {
          data: response.data.map(CatalogMapper.toClient),
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }

  static addToSubscription (id: number) {
    return client.post(`/catalog-position/${id}/add-to-subscription`, {}, ApiVersion.v2)
      .then((response) => {
        return {
          data: response.data,
          headers: HeaderMapper.toClient(response.headers)
        }
      })
  }
}
