import Token from '@web/common/token'
import { BASE_URL, BASE_URL_V2 } from '@web/common/config'

/* istanbul ignore start */
/* istanbul ignore end */
const getUrl = (url: string): string => BASE_URL + url
const getUrlV2 = (url: string): string => BASE_URL_V2 + url
const getAuthToken = (): string => {
  return `Bearer ${Token.get()}`
}

export {
  BASE_URL,
  BASE_URL_V2,
  getUrl,
  getUrlV2,
  getAuthToken
}
